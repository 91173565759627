<!--
 * @Author: your name
 * @Date: 2021-08-06 16:19:28
 * @LastEditTime: 2021-09-25 17:55:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\focus\components\hd.vue
-->
<template>
  <div class="fillcontain">
    <div class="table_container">
      <el-form
        :model="formData"
        ref="formData"
        label-width="120px"
        class="demo-formData"
        style="margin-top: 20px"
      >
        <p style="margin-bottom: 12px">
          您当前作为登录账号的手机号为：{{ oldPhone }}
        </p>

        <el-form-item label="新手机号">
          <el-input
            v-model="formData.phone"
            style="width: 200px"
            type="text"
            maxlength="11"
            show-word-limit
            clearable
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item label="验证码">
          <el-input
            v-model="formData.code"
            style="width: 200px"
            type="text"
            clearable
            placeholder="请输入验证码"
          ></el-input>
          <div style="display: inline-block" @click="getcode()" class="codeBox">
            {{ text }}
          </div>
        </el-form-item>

        <div class="but_bot">
          <el-button
            style="margin-left: 50px"
            type="primary"
            @click="submit()"
            round
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updatePhone,sendCode } from "@/api/index";
import { getUserInfo } from "@/utils/auth";
export default {
  data() {
    return {
      disable: false,
      text: "获取验证码",
      oldPhone: "",
      formData: {
        phone: "",
        code: "",
      },
    };
  },
  methods: {
    timeOut() {
      let that = this;
      this.disable = true;
      var num = 60;
      var timer = setInterval(function () {
        console.log(num);
        num--;
        that.text = num + "秒";
        if (num <= 0) {
          that.disable = false;
          clearInterval(timer);
          that.text = "获取验证码";
        }
      }, 1000); //反复执行函数本身
    },
    getcode() {
      if (!this.formData.phone) {
        this.$message({
          type: "error",
          message: "请输入手机号",
        });
        return false;
      }
      if (this.disable) {
        return false;
      }
      this.timeOut();
      sendCode({
        phone: this.formData.phone,
      });
    },
    submit() {
      if (this.formData.phone == "" || this.formData.code == "") {
        this.$message({
          type: "error",
          message: "请上传完整信息",
        });
      } else {
        updatePhone({
          newPhone: this.formData.phone,
          oldPhone: this.oldPhone,
          code: this.formData.code,
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: "手机号修改成功，请重新登录！",
            });
            this.$store.commit("logout");
            this.$router.push(`/login`);
          })
          .catch((error) => {
            // this.$store.dispatch("app/loading", false);
            // console.log(error);
          });
      }
    },
  },
  created() {
    try {
      this.oldPhone = getUserInfo().phone || "";
    } catch (error) {}
  },
};
</script>

<style scoped>
.codeBox {
  width: 175px;
  height: 39px;
  background: #fff0f0;
  margin-left: 12px;
  cursor: pointer;
  font-size: 19px;
  font-family: MicrosoftYaHei;
  color: #f53244;
  text-align: center;
  line-height: 39px;
}
</style>