<!--
 * @Author: your name
 * @Date: 2021-08-06 16:31:46
 * @LastEditTime: 2021-08-06 17:57:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\focus\components\fwd.vue
-->
<template>
  <div class="fillcontain">
    <div class="table_container">
      <el-form
        :model="formData"
        ref="formData"
        label-width="120px"
        class="demo-formData"
        style="margin-top: 20px"
      >
        <el-form-item label="旧密码">
          <el-input
            v-model="formData.oldPassword"
            style="width: 200px"
            type="password"
            maxlength="16"
            show-word-limit
            clearable
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            v-model="formData.newPassword"
            style="width: 200px"
            type="password"
            maxlength="16"
            show-word-limit
            clearable
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input
            v-model="formData.confirmPassword"
            @keyup.enter.native="submit"
            style="width: 200px"
            type="password"
            maxlength="16"
            show-word-limit
            clearable
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
        <div class="but_bot">
          <el-button
            style="margin-left: 50px"
            type="primary"
            @click="submit()"
            round
            >提交</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { updatePwd } from "@/api/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      formData: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    submit() {
      if (
        this.formData.oldPassword == "" ||
        this.formData.newPassword == "" ||
        this.formData.confirmPassword == ""
      ) {
        this.$message({
          type: "error",
          message: "请上传完整信息",
        });
      } else if (this.formData.newPassword != this.formData.confirmPassword) {
        this.$message({
          type: "error",
          message: "两次密码不一致",
        });
      } else {
        updatePwd({
          oldPwd: md5(this.formData.oldPassword),
          newPwd: md5(this.formData.newPassword),
        })
          .then((res) => {
            this.$message({
              type: "success",
              message: "密码修改成功，请重新登录！",
            });
            this.$store.commit("logout");
            this.$router.push(`/login`);
          })
          .catch((error) => {
            // this.$store.dispatch("app/loading", false);
            // console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
</style>